import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationState, NotificationType } from './types';

const initialState: NotificationState = {
  currentNotification: null,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotification(state, { payload }: PayloadAction<NotificationType>) {
      state.currentNotification = payload;

      if (payload.closeable !== false) {
        state.currentNotification.closeable = true;
      }
    },
    dismissNotification(state) {
      state.currentNotification = null;
    },
  },
});

export const { setNotification, dismissNotification } = notificationsSlice.actions;

export default notificationsSlice;

export * from './selectors';
export * from './types';
