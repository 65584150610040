// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-dev-404-tsx": () => import("./../../../src/pages/dev-404.tsx" /* webpackChunkName: "component---src-pages-dev-404-tsx" */),
  "component---src-pages-fields-tsx": () => import("./../../../src/pages/fields.tsx" /* webpackChunkName: "component---src-pages-fields-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-law-business-tsx": () => import("./../../../src/pages/law-business.tsx" /* webpackChunkName: "component---src-pages-law-business-tsx" */),
  "component---src-pages-law-civil-tsx": () => import("./../../../src/pages/law-civil.tsx" /* webpackChunkName: "component---src-pages-law-civil-tsx" */),
  "component---src-pages-law-consumer-protection-tsx": () => import("./../../../src/pages/law-consumer-protection.tsx" /* webpackChunkName: "component---src-pages-law-consumer-protection-tsx" */),
  "component---src-pages-law-criminal-tsx": () => import("./../../../src/pages/law-criminal.tsx" /* webpackChunkName: "component---src-pages-law-criminal-tsx" */),
  "component---src-pages-law-family-tsx": () => import("./../../../src/pages/law-family.tsx" /* webpackChunkName: "component---src-pages-law-family-tsx" */),
  "component---src-pages-law-intellectual-property-tsx": () => import("./../../../src/pages/law-intellectual-property.tsx" /* webpackChunkName: "component---src-pages-law-intellectual-property-tsx" */),
  "component---src-pages-law-labour-tsx": () => import("./../../../src/pages/law-labour.tsx" /* webpackChunkName: "component---src-pages-law-labour-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-useful-links-tsx": () => import("./../../../src/pages/useful-links.tsx" /* webpackChunkName: "component---src-pages-useful-links-tsx" */),
  "component---src-templates-news-post-index-tsx": () => import("./../../../src/templates/NewsPost/index.tsx" /* webpackChunkName: "component---src-templates-news-post-index-tsx" */)
}

