import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import notificationsSlice from './notifications';
import newsSlice from './news';

let store: AppStore;

export const makeStore = () => {
  const createdStore = configureStore({
    reducer: {
      [notificationsSlice.name]: notificationsSlice.reducer,
      [newsSlice.name]: newsSlice.reducer,
    },
    devTools:
      process.env.NODE_ENV === 'production'
        ? undefined
        : {
          name: 'SZK.com',
          latency: 20,
          maxAge: 20,
          shouldCatchErrors: true,
        },
  });

  store = createdStore;

  return createdStore;
};

export const getStore = () => store;

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

const wrapper = makeStore();

export default wrapper;
