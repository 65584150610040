import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NewsState } from './types';

const initialState: NewsState = {
  searchFilter: null,
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNewsFilter(state, { payload }: PayloadAction<string | null>) {
      state.searchFilter = payload;
    },
    clearNewsFilter(state) {
      state.searchFilter = null;
    },
  },
});

export const { setNewsFilter, clearNewsFilter } = newsSlice.actions;

export default newsSlice;

export * from './selectors';
export * from './types';
